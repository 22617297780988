import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 22,
    title: "La gestione degli errori con React",
    desc: "",
    img: "/blog-image/react-error.jpg",
    page: "blog/react-error",
    data: "29 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "La gestione degli errori è una parte cruciale nello sviluppo di qualsiasi applicazione web - React, framework JavaScript, mette a disposizione potenti tool per gestire gli errori in modo efficace.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        La gestione degli errori è una parte
                                        cruciale nello sviluppo di qualsiasi
                                        applicazione web - React, framework
                                        JavaScript, mette a disposizione potenti
                                        tool per gestire gli errori in modo
                                        efficace. Nel testo, esploriamo le
                                        diverse tecniche e approcci per
                                        affrontare e gestire gli errori in
                                        un'app React.
                                    </p>

                                    <p>
                                        Gli errori possono verificarsi in
                                        qualsiasi momento durante lo sviluppo di
                                        un'app. Possono essere errori di
                                        sintassi, errori di runtime o
                                        addirittura errori imprevisti causati da
                                        dati non validi. La loro gestione è
                                        quindi fondamentale per garantire che
                                        l'applicazione rimanga stabile e
                                        reattiva, offrendo nel contempo
                                        un'esperienza utente senza interruzioni.
                                    </p>

                                    <h3>Error Boundaries</h3>

                                    <p>
                                        React introduce il concetto di "Error
                                        Boundaries" - un componente React che
                                        funge da "contenitore" per gli errori
                                        verificatisi nei suoi componenti figli.
                                        Quando un errore si verifica all'interno
                                        di un componente figlio, React cerca
                                        l'Error Boundary più vicino nell'albero
                                        del componente e attiva il suo metodo
                                        `componentDidCatch` per gestire
                                        l'errore.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`class ErrorBoundary extends React.Component {
componentDidCatch(error, info) {
    // Gestire l'errore qui
}

    render() {
        return this.props.children;
    }
}`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        È importante notare che gli Error
                                        Boundary non possono catturare errori
                                        verificatisi durante la gestione degli
                                        eventi asincroni, nell'implementazione
                                        di `render` o all'interno delle funzioni
                                        utilizzate nei componenti. Pertanto, è
                                        consigliabile utilizzarli con attenzione
                                        e in combinazione con altre tecniche.
                                    </p>
                                    <h3>Gestione degli errori asincroni</h3>
                                    <p>
                                        Con l'aumento dell'uso di chiamate
                                        asincrone e fetch API nelle applicazioni
                                        React, è molto importante gestire gli
                                        errori che possono verificarsi in queste
                                        situazioni. Una delle pratiche comuni è
                                        l'uso delle promesse e delle istruzioni
                                        `try...catch` per catturare gli errori.
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`async function fetchData() {
    try {
        const response = await fetch('https://api.example.com/data');
        const data = await response.json();
            // Utilizzare i dati ottenuti
    } catch (error) {
        // Gestire l'errore qui
    }
} `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />

                                    <p>
                                        Questo approccio consente di catturare
                                        gli errori durante le chiamate asincrone
                                        e di fornire una gestione
                                        personalizzata.
                                    </p>
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <h3>Visualizzazione degli errori</h3>
                                    <p>
                                        Una parte essenziale della gestione
                                        degli errori è la comunicazione di
                                        questi agli utenti in modo chiaro e
                                        informativo. È possibile utilizzare
                                        componenti React per visualizzare
                                        messaggi di errore o interfacce utente
                                        alternative quando si verificano errori.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, errorMessage: error.message });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Si è verificato un errore</h1>
                    <p>{this.state.errorMessage}</p>
                </div>
            );
        }
            return this.props.children;
    }
} `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />

                                    <p>
                                        In questo esempio, l'Error Boundary
                                        mostra un messaggio di errore quando un
                                        errore viene catturato.
                                    </p>
                                    <h3>Gestione degli errori globali</h3>
                                    <p>
                                        In alcuni casi, potrebbe essere
                                        necessario gestire gli errori
                                        globalmente nell'applicazione React - ad
                                        esempio per registrare gli errori o
                                        inviarli a un server di monitoraggio
                                        degli errori. È possibile farlo
                                        sfruttando il componente
                                        `componentDidCatch` di livello superiore
                                        nell'albero dei componenti.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`class App extends React.Component {
componentDidCatch(error, info) {
    // Invia l'errore a un server di monitoraggio degli errori
    logErrorToServer(error, info);
}

render() {
        return (
            <div>
            {/* Componenti dell'app */}
            </div>
        );
    }
}`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        In questo modo, si centralizza la
                                        gestione degli errori e si garantisce
                                        che tutti gli errori vengano registrati
                                        e gestiti correttamente.
                                    </p>
                                    <p>
                                        È importante valutare attentamente le
                                        esigenze specifiche dell'applicazione e
                                        garantire una comunicazione chiara degli
                                        errori agli utenti. Ci sono strumenti di
                                        monitoraggio - come Sentry o Rollbar -
                                        per rilevare e monitorare gli errori in
                                        tempo reale, che consentono un'analisi
                                        approfondita e una risoluzione
                                        tempestiva degli errori. Vedremo di
                                        parlarne in qualche prossimo articolo.
                                    </p>
                                    <p>
                                        Al fine di garantire una gestione degli
                                        errori ben progettata, è importante
                                        affidarsi a tecnici specializzati.
                                        Realizza applicazioni React robuste e
                                        affidabili, richiedi una consulenza all
                                        nostro team!
                                    </p>
                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
